
import './LoaderStyles.css';

const Loader = () => {
  return (
    <div className="loader-overlay">
      <img loading='eager' src={`${process.env.REACT_APP_CDN_IMAGE_RESOURCE_URL}X-BRAND-LOADER.gif`} alt="loader" width={80} height={80} />
    </div>
  );
};

export default Loader;