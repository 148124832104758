import WiseXRoute from './Routes/Routes';

/**
 * @function App
 * @description The main component of the application.
 * It renders the entire routing structure of the application.
 * @returns {JSX.Element} The app component
 */
function App() {
  return <WiseXRoute />;
}

export default App;
