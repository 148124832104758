import { Suspense, lazy, useCallback, useEffect } from 'react';
import { useApiService } from '../../../Redux/Services/Api';
import { setUserData } from '../../../Redux/Reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Shared/UI/Loader/Loader';
// import Imvest from '../../../Components/Shared/UI/InvestmentCalculator/InvestmentCalculator'

import { useImagePreload, usePageMeta } from '../../../Components/Shared/Hooks/CustomHooks';
import { HERO_BG_DESKTOP_IMAGE_URL, HERO_BG_MOBILE_IMAGE_URL, HERO_IMG, HERO_IMG_MOBILE } from '../../../Utils/constants';
const FeaturedBlogs = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/FeaturedBlogs/FeaturedBlogs'));
const Awards = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/Awards/Awards'));
const MediaCoverage = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/MediaCoverage/MediaCoverage'));
const Testimonials = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/Testimonials/Testimonials'));
const WeWorkWith = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/WeWorkWith/WeWorkWith'));
const HowToInvest = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/HowToInvest/HowToInvest'));
const WisexFactor = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/WisexFextor/WisexFactor'));
const Listings = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/Listings/Listings'));
const StartWithWisex = lazy(() => import('../../../Components/Shared/UI/StartWithWisex/StartWithWisex'));
const Hero = lazy(() => import('../../../Components/Layout/PreLogin/HomePage/Hero/Hero'));
const Header = lazy(() => import('../../../Components/Shared/UI/Header/Header'));
const Footer = lazy(() => import('../../../Components/Shared/UI/Footer/Footer'));

const HomePage = () => {
  usePageMeta()
  // preloading hero images
  useImagePreload(HERO_BG_DESKTOP_IMAGE_URL, HERO_BG_MOBILE_IMAGE_URL)
  useImagePreload(HERO_IMG, HERO_IMG_MOBILE)

  const { postData } = useApiService();
  const dispatch = useDispatch();
  const refreshToken = useSelector(userData => userData?.userData?.token)

  const refresh = useCallback(async () => {

    const refreshApi = process.env.REACT_APP_REFRESH_TOKEN;
    try {
      const response = await postData(refreshApi, {}, false, false);
      if (response?.success) {
        if (response?.data) {
          if (!localStorage.getItem('userData')) {
            dispatch(setUserData(response?.data));
            // localStorage.setItem('userData', JSON.stringify(response?.data));
            // window.location.reload();
          } else {
            dispatch(setUserData(response?.data));
            // localStorage.setItem('userData', JSON.stringify(response?.data));
          }
        }
      }
    } catch (e) {
      console.log('error while refreshing:', e);
      dispatch(setUserData({}));
    }
  }, []);



  useEffect(() => {
    if (refreshToken) {
      refresh();
    }
  }, [refresh])


  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <Hero />
      <Listings />
      <WisexFactor />
      <HowToInvest />
      <WeWorkWith />
      <Testimonials />
      <MediaCoverage />
      <FeaturedBlogs />
      <Awards />
      <StartWithWisex
        heading="Begin Building"
        headingSpan="Your Wealth with a Real Estate Portfolio Today!"
      />
      <Footer />
    </Suspense>
  );
};

export default HomePage;


